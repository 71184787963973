var _a, _b;
import { get, identity } from 'lodash';
import { getLayoutName, LAYOUT_CARD_MEDIUM, LAYOUT_SIDE_BY_SIDE, LAYOUT_TEXT_ON_IMAGE_MEDIUM, LAYOUT_GRID, LAYOUT_CARD_SMALL, LAYOUT_TEXT_ON_IMAGE_SMALL, LAYOUT_CARD_LARGE, LAYOUT_FULL_POST, LAYOUT_ONE_COLUMN_MOBILE, LAYOUT_SIDE_BY_SIDE_MOBILE, LAYOUT_TEXT_ON_IMAGE_MOBILE, LAYOUT_PG_TEXT_ON_IMAGE_SMALL, LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM, LAYOUT_PG_TEXT_ON_IMAGE_LARGE, LAYOUT_PG_CARD_MEDIUM, LAYOUT_PG_SIDE_BY_SIDE, LAYOUT_PG_SIDE_BY_SIDE_RIGHT, LAYOUT_PG_GRID, LAYOUT_PG_GRID_INTERMEDIATE, LAYOUT_PG_GRID_LARGE, LAYOUT_PG_ONE_COLUMN, LAYOUT_LIST, LAYOUT_LIST_MEDIUM, LAYOUT_LIST_LARGE, LAYOUT_SLIDER, } from './layout-types';
import { SECTION_HOMEPAGE, SECTION_MY_POSTS } from './sections';
// Mobile
export var IS_MOBILE_DISPLAY_SETTINGS_ENABLED = 'blog-mobile-isMobileDisplaySettingsEnabled';
export var IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH = "style.booleans.".concat(IS_MOBILE_DISPLAY_SETTINGS_ENABLED);
export var IS_VIEW_COUNT_ENABLED_MOBILE = 'blog-mobile-isViewCountEnabled';
export var IS_VIEW_COUNT_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_VIEW_COUNT_ENABLED_MOBILE);
export var IS_MORE_OPTIONS_MENU_ENABLED_MOBILE = 'blog-mobile-isMoreOptionsMenuEnabled';
export var IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_MORE_OPTIONS_MENU_ENABLED_MOBILE);
export var IS_COMMENT_COUNT_ENABLED_MOBILE = 'blog-mobile-isCommentCountEnabled';
export var IS_COMMENT_COUNT_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_COMMENT_COUNT_ENABLED_MOBILE);
export var IS_LIKE_COUNT_ENABLED_MOBILE = 'blog-mobile-isLikeCountEnabled';
export var IS_LIKE_COUNT_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_LIKE_COUNT_ENABLED_MOBILE);
export var IS_AUTHOR_NAME_ENABLED_MOBILE = 'blog-mobile-isAuthorNameEnabled';
export var IS_AUTHOR_NAME_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_AUTHOR_NAME_ENABLED_MOBILE);
export var IS_AUTHOR_PICTURE_ENABLED_MOBILE = 'blog-mobile-isAuthorPictureEnabled';
export var IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_AUTHOR_PICTURE_ENABLED_MOBILE);
export var IS_AUTHOR_BADGE_ENABLED_MOBILE = 'blog-mobile-isAuthorBadgeEnabled';
export var IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_AUTHOR_BADGE_ENABLED_MOBILE);
export var AUTHOR_INFO_TYPE_MOBILE = 'blog-mobile-authorInfoType';
export var AUTHOR_INFO_TYPE_MOBILE_PATH = "style.numbers.".concat(AUTHOR_INFO_TYPE_MOBILE);
export var IS_POST_DATE_ENABLED_MOBILE = 'blog-mobile-isPostDateEnabled';
export var IS_POST_DATE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_POST_DATE_ENABLED_MOBILE);
export var IS_COVER_IMAGE_ENABLED_MOBILE = 'blog-mobile-isCoverImageEnabled';
export var IS_COVER_IMAGE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_COVER_IMAGE_ENABLED_MOBILE);
export var IS_POST_UPDATED_DATE_ENABLED_MOBILE = 'blog-mobile-isPostUpdatedDateEnabled';
export var IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_POST_UPDATED_DATE_ENABLED_MOBILE);
export var IS_RECENT_POSTS_ENABLED_MOBILE = 'blog-mobile-isRecentPostsEnabled';
export var IS_RECENT_POSTS_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_RECENT_POSTS_ENABLED_MOBILE);
export var IS_COMMENTS_ENABLED_MOBILE = 'blog-mobile-isCommentsEnabled';
export var IS_COMMENTS_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_COMMENTS_ENABLED_MOBILE);
export var IS_CATEGORY_LABELS_ENABLED_MOBILE = 'blog-mobile-isCategoryLabelsEnabled';
export var IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_CATEGORY_LABELS_ENABLED_MOBILE);
export var IS_POST_TITLE_ENABLED_MOBILE = 'blog-mobile-isPostTitleEnabled';
export var IS_POST_TITLE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_POST_TITLE_ENABLED_MOBILE);
export var IS_POST_PUBLISH_DATE_ENABLED_MOBILE = 'blog-mobile-isPostPublishDateEnabled';
export var IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_POST_PUBLISH_DATE_ENABLED_MOBILE);
export var IS_READING_TIME_ENABLED_MOBILE = 'blog-mobile-isReadingTimeEnabled';
export var IS_READING_TIME_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_READING_TIME_ENABLED_MOBILE);
export var IS_POST_DESCRIPTION_ENABLED_MOBILE = 'blog-mobile-isPostDescriptionEnabled';
export var IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_POST_DESCRIPTION_ENABLED_MOBILE);
export var IS_BLOG_MENU_ENABLED_MOBILE = 'blogMenu-mobile-isEnabled';
export var IS_BLOG_MENU_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_BLOG_MENU_ENABLED_MOBILE);
export var IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE = 'blogMenu-mobile-isCategoryLabelsEnabled';
export var IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE);
export var IS_BLOG_MENU_SEARCH_ENABLED_MOBILE = 'blogMenu-mobile-isSearchEnabled';
export var IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_BLOG_MENU_SEARCH_ENABLED_MOBILE);
export var IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE = 'blogMenu-mobile-isLoginButtonEnabled';
export var IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE);
export var IS_SOCIAL_SHARING_ENABLED_MOBILE = 'socialSharing-mobile-isEnabled';
export var IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_SOCIAL_SHARING_ENABLED_MOBILE);
export var IS_PINTEREST_ENABLED = 'pinterest-isEnabled';
export var IS_PINTEREST_ENABLED_PATH = "style.booleans.".concat(IS_PINTEREST_ENABLED);
export var IS_TWITTER_ENABLED = 'twitter-isEnabled';
export var IS_TWITTER_ENABLED_PATH = "style.booleans.".concat(IS_TWITTER_ENABLED);
// Feed metadata display settings
export var IS_VIEW_COUNT_ENABLED = 'blog-isViewCountEnabled';
export var IS_VIEW_COUNT_ENABLED_PATH = "style.booleans.".concat(IS_VIEW_COUNT_ENABLED);
export var IS_COMMENT_COUNT_ENABLED = 'blog-isCommentCountEnabled';
export var IS_COMMENT_COUNT_ENABLED_PATH = "style.booleans.".concat(IS_COMMENT_COUNT_ENABLED);
export var IS_LIKE_COUNT_ENABLED = 'blog-isLikeCountEnabled';
export var IS_LIKE_COUNT_ENABLED_PATH = "style.booleans.".concat(IS_LIKE_COUNT_ENABLED);
export var IS_AUTHOR_NAME_ENABLED = 'blog-isAuthorNameEnabled';
export var IS_AUTHOR_NAME_ENABLED_PATH = "style.booleans.".concat(IS_AUTHOR_NAME_ENABLED);
export var IS_AUTHOR_PICTURE_ENABLED = 'blog-isAuthorPictureEnabled';
export var IS_AUTHOR_PICTURE_ENABLED_PATH = "style.booleans.".concat(IS_AUTHOR_PICTURE_ENABLED);
export var IS_AUTHOR_BADGE_ENABLED = 'blog-isAuthorBadgeEnabled';
export var IS_AUTHOR_BADGE_ENABLED_PATH = "style.booleans.".concat(IS_AUTHOR_BADGE_ENABLED);
export var AUTHOR_INFO_TYPE = 'blog-authorInfoType';
export var AUTHOR_INFO_TYPE_PATH = "style.numbers.".concat(AUTHOR_INFO_TYPE);
export var AUTHOR_INFO_TYPE_NAME_PICTURE = 0;
export var AUTHOR_INFO_TYPE_NAME = 1;
export var IS_POST_DATE_ENABLED = 'blog-isPostDateEnabled';
export var IS_POST_DATE_ENABLED_PATH = "style.booleans.".concat(IS_POST_DATE_ENABLED);
export var IS_POST_UPDATED_DATE_ENABLED = 'blog-isPostUpdatedDateEnabled';
export var IS_POST_UPDATED_DATE_ENABLED_PATH = "style.booleans.".concat(IS_POST_UPDATED_DATE_ENABLED);
export var IS_RECENT_POSTS_ENABLED = 'blog-isRecentPostsEnabled';
export var IS_RECENT_POSTS_ENABLED_PATH = "style.booleans.".concat(IS_RECENT_POSTS_ENABLED);
export var IS_COMMENTS_ENABLED = 'blog-isCommentsEnabled';
export var IS_COMMENTS_ENABLED_PATH = "style.booleans.".concat(IS_COMMENTS_ENABLED);
export var IS_ANONYMOUS_COMMENTS_ENABLED = 'blog-isAnonymousCommentsEnabled';
export var IS_ANONYMOUS_COMMENTS_ENABLED_PATH = "style.booleans.".concat(IS_ANONYMOUS_COMMENTS_ENABLED);
export var IS_CATEGORY_LABELS_ENABLED = 'blog-isCategoryLabelsEnabled';
export var IS_CATEGORY_LABELS_ENABLED_PATH = "style.booleans.".concat(IS_CATEGORY_LABELS_ENABLED);
export var IS_TAGS_ENABLED = 'blog-isTagsEnabled';
export var IS_TAGS_ENABLED_PATH = "style.booleans.".concat(IS_TAGS_ENABLED);
export var IS_TAGS_ENABLED_MOBILE = 'blog-mobile-isTagsEnabled';
export var IS_TAGS_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_TAGS_ENABLED_MOBILE);
export var IS_POST_TITLE_ENABLED = 'blog-isPostTitleEnabled';
export var IS_POST_TITLE_ENABLED_PATH = "style.booleans.".concat(IS_POST_TITLE_ENABLED);
export var IS_POST_PUBLISH_DATE_ENABLED = 'blog-isPostPublishDateEnabled';
export var IS_POST_PUBLISH_DATE_ENABLED_PATH = "style.booleans.".concat(IS_POST_PUBLISH_DATE_ENABLED);
export var IS_READING_TIME_ENABLED = 'blog-isReadingTimeEnabled';
export var IS_READING_TIME_ENABLED_PATH = "style.booleans.".concat(IS_READING_TIME_ENABLED);
export var IS_MORE_OPTIONS_MENU_ENABLED = 'blog-isMoreOptionsMenuEnabled';
export var IS_MORE_OPTIONS_MENU_ENABLED_PATH = "style.booleans.".concat(IS_MORE_OPTIONS_MENU_ENABLED);
export var IS_POST_DESCRIPTION_ENABLED = 'blog-isPostDescriptionEnabled';
export var IS_POST_DESCRIPTION_ENABLED_PATH = "style.booleans.".concat(IS_POST_DESCRIPTION_ENABLED);
export var IS_COVER_IMAGE_ENABLED = 'blog-isCoverImageEnabled';
export var IS_COVER_IMAGE_ENABLED_PATH = "style.booleans.".concat(IS_COVER_IMAGE_ENABLED);
export var IS_CATEGORY_LABEL_ENABLED = 'blog-isCategoryLabelEnabled';
export var IS_CATEGORY_LABEL_ENABLED_PATH = "style.booleans.".concat(IS_CATEGORY_LABEL_ENABLED);
export var IS_CATEGORY_LABEL_ENABLED_MOBILE = 'blog-mobile-isCategoryLabelEnabled';
export var IS_CATEGORY_LABEL_ENABLED_MOBILE_PATH = "style.booleans.".concat(IS_CATEGORY_LABEL_ENABLED_MOBILE);
// Full widith
export var IS_POST_LIST_FULL_WIDTH_ENABLED = 'blog-isPostListFullWidthEnabled';
export var IS_POST_LIST_FULL_WIDTH_ENABLED_PATH = "style.booleans.".concat(IS_POST_LIST_FULL_WIDTH_ENABLED);
// Layout params
export var IS_MOBILE_LAYOUT_SETTINGS_ENABLED = 'blog-mobile-isMobileLayoutSettingsEnabled';
export var IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH = "style.booleans.".concat(IS_MOBILE_LAYOUT_SETTINGS_ENABLED);
export var IS_POST_DESIGN_IN_FULL_POST_ENABLED = 'blog-postDesignInFullpostEnabled';
export var IS_POST_DESIGN_IN_FULL_POST_ENABLED_PATH = "style.booleans.".concat(IS_POST_DESIGN_IN_FULL_POST_ENABLED);
var getLayoutParam = function (section, param, isMobile) {
    return "layout-".concat(isMobile ? 'mobile-' : '').concat(section, "-").concat(param);
};
export var getLayoutType = function (section, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return getLayoutParam(section, 'layoutType', isMobile);
};
export var getLayoutTypePath = function (section, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return "style.numbers.".concat(getLayoutType(section, isMobile));
};
var getPGLayoutParam = function (param) { return function (section, layoutName, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return "layout-".concat(isMobile ? 'mobile-' : '').concat(section, "-").concat(layoutName, "-").concat(param);
}; };
var getPGLayoutParamPath = function (getLayoutParamFn) { return function (section, layoutName, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return "style.numbers.".concat(getLayoutParamFn(section, layoutName, isMobile));
}; };
export var getLayoutSpacingParam = getPGLayoutParam('layoutSpacing');
export var getLayoutPostSizeParam = getPGLayoutParam('layoutPostSize');
export var getLayoutCardsRowTypeParam = getPGLayoutParam('layoutCardsRowType');
export var getLayoutPostsPerRowParam = getPGLayoutParam('layoutPostsPerRow');
export var getLayoutContentHeightParam = getPGLayoutParam('contentHeight');
export var getLayoutContentHeightTypeParam = getPGLayoutParam('contentHeightType');
export var getLayoutMarginsParam = getPGLayoutParam('margins');
export var getLayoutTitleLineCountParam = getPGLayoutParam('titleLineCount');
export var getLayoutDescriptionLineCountParam = getPGLayoutParam('descriptionLineCount');
export var getLayoutSidesPaddingParam = getPGLayoutParam('sidesPadding');
export var getLayoutImageRatioParam = getPGLayoutParam('imageRatio');
export var getLayoutImageAlignmentParam = getPGLayoutParam('imageAlignment');
export var getLayoutImageProportionsParam = getPGLayoutParam('imageProportions');
export var getLayoutImageCropTypeParam = getPGLayoutParam('imageCropType');
export var getLayoutImageResizingModeParam = getPGLayoutParam('imageResizingMode');
export var getLayoutImageWidthParam = getPGLayoutParam('imageWidth');
export var getLayoutSpacingParamPath = getPGLayoutParamPath(getLayoutSpacingParam);
export var getLayoutPostSizeParamPath = getPGLayoutParamPath(getLayoutPostSizeParam);
export var getLayoutCardsRowTypeParamPath = getPGLayoutParamPath(getLayoutCardsRowTypeParam);
export var getLayoutPostsPerRowParamPath = getPGLayoutParamPath(getLayoutPostsPerRowParam);
export var getLayoutImageRatioParamPath = getPGLayoutParamPath(getLayoutImageRatioParam);
export var getLayoutContentHeightParamPath = getPGLayoutParamPath(getLayoutContentHeightParam);
export var getLayoutContentHeightTypeParamPath = getPGLayoutParamPath(getLayoutContentHeightTypeParam);
export var getLayoutMarginsParamPath = getPGLayoutParamPath(getLayoutMarginsParam);
export var getLayoutTitleLineCountParamPath = getPGLayoutParamPath(getLayoutTitleLineCountParam);
export var getLayoutDescriptionLineCountParamPath = getPGLayoutParamPath(getLayoutDescriptionLineCountParam);
export var getLayoutSidesPaddingParamPath = getPGLayoutParamPath(getLayoutSidesPaddingParam);
export var getLayoutImageAlignmentParamPath = getPGLayoutParamPath(getLayoutImageAlignmentParam);
export var getLayoutImageProportionsParamPath = getPGLayoutParamPath(getLayoutImageProportionsParam);
export var getLayoutImageCropTypeParamPath = getPGLayoutParamPath(getLayoutImageCropTypeParam);
export var getLayoutImageResizingModeParamPath = getPGLayoutParamPath(getLayoutImageResizingModeParam);
export var getLayoutImageWidthParamPath = getPGLayoutParamPath(getLayoutImageWidthParam);
var getPaginationParam = function (section, param) { return "pagination-".concat(section, "-").concat(param); };
export var getPaginationPageSize = function (section) {
    return getPaginationParam(section, 'pageSize');
};
export var getPaginationPageSizePath = function (section) {
    return "style.numbers.".concat(getPaginationPageSize(section));
};
export var getPaginationType = function (section) {
    return getPaginationParam(section, 'type');
};
export var getPaginationTypePath = function (section) {
    return "style.numbers.".concat(getPaginationType(section));
};
export var getSBSLayoutImagePositionParam = function (section) {
    return "blog-sideBySideLayoutImagePosition-".concat(section);
};
export var getSBSLayoutImagePositionParamPath = function (section) {
    return "style.numbers.".concat(getSBSLayoutImagePositionParam(section));
};
// Post design params
export var IS_MOBILE_DESIGN_SETTINGS_ENABLED = 'blog-mobile-isMobileDesignSettingsEnabled';
export var IS_MOBILE_DESIGN_SETTINGS_ENABLED_PATH = "style.booleans.".concat(IS_MOBILE_DESIGN_SETTINGS_ENABLED);
export var TITLE_FONT_SIZE = 'titleFontSize';
export var DESCRIPTION_FONT_SIZE = 'descriptionFontSize';
export var IS_FEED_DESIGN_PROPS_MIGRATED = 'blog-isFeedDesignPropsMigrated';
export var IS_FEED_DESIGN_PROPS_MIGRATED_PATH = "style.booleans.".concat(IS_FEED_DESIGN_PROPS_MIGRATED);
export var IS_CONFIGURABLE_LINE_CLAMPING_ENABLED = 'blog-isConfigurableLineClampingEnabled';
export var IS_CONFIGURABLE_LINE_CLAMPING_ENABLED_PATH = "style.booleans.".concat(IS_CONFIGURABLE_LINE_CLAMPING_ENABLED);
export var POST_TITLE_COLOR = 'post-titleColor';
export var POST_TEXT_COLOR = 'post-textColor';
export var POST_TEXT_COLOR_PATH = "style.colors.".concat(POST_TEXT_COLOR);
export var POST_TITLE_FONT = 'post-titleFont';
export var POST_TITLE_FONT_PATH = "style.fonts.".concat(POST_TITLE_FONT);
export var POST_DESCRIPTION_FONT = 'post-descriptionFont';
export var POST_HOMEPAGE_DESCRIPTION_FONT = 'post-homepage-descriptionFont';
export var POST_HOMEPAGE_DESCRIPTION_COLOR = 'post-homepage-descriptionColor';
export var POST_PAGE_FONT = 'post-pageFont';
export var POST_PAGE_FONT_PATH = "style.fonts.".concat(POST_PAGE_FONT);
export var POST_TITLE_FONT_SIZE_MOBILE = "post-mobile-".concat(TITLE_FONT_SIZE);
export var POST_TITLE_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_TITLE_FONT_SIZE_MOBILE);
export var POST_PAGE_FONT_SIZE_MOBILE = 'post-mobile-pageFontSize';
export var POST_PAGE_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_PAGE_FONT_SIZE_MOBILE);
export var getPostLayoutParamByName = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    return "post-".concat(params.filter(identity).join('-'));
};
export var getPostLayoutParamByType = function (layoutType, section, param, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return getPostLayoutParamByName(getLayoutName(layoutType), section, isMobile ? 'mobile' : '', param);
};
export var getPostTitleFontSize = function (layout, section, isMobile) {
    return getPostLayoutParamByType(layout, section, TITLE_FONT_SIZE, isMobile);
};
export var getPostDescriptionFontSize = function (layout, section, isMobile) {
    return getPostLayoutParamByType(layout, section, DESCRIPTION_FONT_SIZE, isMobile);
};
export var getPostMetadataFontSize = function (layout, section) {
    return getPostLayoutParamByType(layout, section, 'metadataFontSize');
};
export var getPostFontSizesPath = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return "style.numbers.".concat(getPostLayoutParamByType.apply(void 0, args));
};
export var getGenericPostFontPath = function (section, font) {
    return "style.fonts.".concat(getPostLayoutParamByName(section, font));
};
export var getCardBorderWidth = function (layout, section) {
    return "post-".concat(getLayoutName(layout), "-").concat(section, "-cardBorderWidth");
};
export var getCardBorderWidthPath = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return "style.numbers.".concat(getCardBorderWidth.apply(void 0, args));
};
export var getFeedBorderColor = function (section) { return "post-".concat(section, "-borderColor"); };
export var getFeedBorderColorPath = function (section) {
    return "style.colors.".concat(getFeedBorderColor(section));
};
export var getMobileFeedBorderColor = function (section) {
    return "post-".concat(section, "-mobile-borderColor");
};
export var getMobileFeedBorderColorPath = function (section) {
    return "style.colors.".concat(getMobileFeedBorderColor(section));
};
export var CARD_BACKGROUND_COLOR = 'post-cardBackgroundColor';
export var CARD_BACKGROUND_COLOR_PATH = "style.colors.".concat(CARD_BACKGROUND_COLOR);
export var CARD_BORDER_COLOR = 'post-cardBorderColor';
export var CARD_BORDER_WIDTH = 'post-cardBorderWidth';
export var CARD_BORDER_WIDTH_PATH = "style.numbers.".concat(CARD_BORDER_WIDTH);
export var APP_BACKGROUND_COLOR = 'post-appBackgroundColor';
export var APP_BACKGROUND_COLOR_PATH = "style.colors.".concat(APP_BACKGROUND_COLOR);
export var NAVIGATION_BACKGROUND_COLOR = 'navigation-backgroundColor';
export var NAVIGATION_BACKGROUND_COLOR_PATH = "style.colors.".concat(NAVIGATION_BACKGROUND_COLOR);
export var DEFAULT_NAVIGATION_BACKGROUND_COLOR = 'rgba(255,255,255,1)';
export var NAVIGATION_TEXT_COLOR = 'navigation-textColor';
export var NAVIGATION_TEXT_COLOR_PATH = "style.colors.".concat(NAVIGATION_TEXT_COLOR);
export var NAVIGATION_TEXT_COLOR_ACTIVE = 'navigation-textColorActive';
export var NAVIGATION_TEXT_COLOR_ACTIVE_PATH = "style.colors.".concat(NAVIGATION_TEXT_COLOR_ACTIVE);
export var LAYOUT_SETTINGS_PROPS = (_a = {},
    _a[LAYOUT_CARD_MEDIUM] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 28,
            startWithSize: 26,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 16,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_SIDE_BY_SIDE] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 28,
            startWithSize: 28,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 16,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_TEXT_ON_IMAGE_MEDIUM] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 28,
            startWithSize: 26,
            startWithColor: '#FFFFFF',
        },
        descriptionFont: {
            hideSize: true,
            startWithSize: 16,
            startWithColor: '#FFFFFF',
        },
    },
    _a[LAYOUT_GRID] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 22,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 16,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_CARD_SMALL] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 22,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 16,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_TEXT_ON_IMAGE_SMALL] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 28,
            startWithSize: 28,
            startWithColor: '#FFFFFF',
        },
        descriptionFont: {
            startWithColor: '#FFFFFF',
            startWithSize: 16,
            hideSize: true,
        },
    },
    _a[LAYOUT_CARD_LARGE] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 28,
            startWithSize: 28,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 16,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_FULL_POST] = {
        titleFont: {
            fontMinSize: 28,
            fontMaxSize: 176,
            startWithSize: 40,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            startWithColor: 'color-5',
            fontMinSize: 14,
            fontMaxSize: 176,
            startWithSize: 18,
        },
    },
    _a[LAYOUT_ONE_COLUMN_MOBILE] = {
        titleFont: {
            fontMinSize: 12,
            fontMaxSize: 30,
            startWithSize: 22,
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 18,
            startWithSize: 14,
        },
    },
    _a[LAYOUT_SIDE_BY_SIDE_MOBILE] = {
        titleFont: {
            fontMinSize: 12,
            fontMaxSize: 30,
            startWithSize: 14,
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 18,
            startWithSize: 14,
        },
    },
    _a[LAYOUT_TEXT_ON_IMAGE_MOBILE] = {
        titleFont: {
            fontMinSize: 12,
            fontMaxSize: 30,
            startWithSize: 22,
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 18,
            startWithSize: 16,
        },
    },
    _a[LAYOUT_PG_TEXT_ON_IMAGE_SMALL] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 22,
            startWithColor: '#FFFFFF',
        },
        descriptionFont: {
            hideSize: true,
            startWithSize: 16,
            startWithColor: '#FFFFFF',
        },
    },
    _a[LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 26,
            startWithColor: '#FFFFFF',
        },
        descriptionFont: {
            hideSize: true,
            startWithSize: 16,
            startWithColor: '#FFFFFF',
        },
    },
    _a[LAYOUT_PG_TEXT_ON_IMAGE_LARGE] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 28,
            startWithColor: '#FFFFFF',
        },
        descriptionFont: {
            hideSize: true,
            startWithSize: 16,
            startWithColor: '#FFFFFF',
        },
    },
    _a[LAYOUT_PG_CARD_MEDIUM] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 26,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_PG_SIDE_BY_SIDE] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 28,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_PG_SIDE_BY_SIDE_RIGHT] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 28,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_PG_GRID] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_PG_GRID_INTERMEDIATE] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_PG_GRID_LARGE] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_PG_ONE_COLUMN] = {
        titleFont: {
            fontMinSize: 16,
            fontMaxSize: 176,
            startWithSize: 28,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 12,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a[LAYOUT_LIST] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 14,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 14,
            startWithColor: 'color-5',
            hideSize: true,
        },
    },
    _a[LAYOUT_LIST_MEDIUM] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 18,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 14,
            startWithColor: 'color-5',
            hideSize: true,
        },
    },
    _a[LAYOUT_LIST_LARGE] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 14,
            startWithColor: 'color-5',
            hideSize: true,
        },
    },
    _a[LAYOUT_SLIDER] = {
        titleFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _a);
var LAYOUT_SETTINGS_PROPS_MY_POSTS = (_b = {},
    _b[LAYOUT_PG_SIDE_BY_SIDE] = {
        titleFont: {
            hideTheme: false,
            fontMinSize: 16,
            fontMaxSize: 28,
            startWithSize: 22,
            startWithColor: 'color-5',
        },
        descriptionFont: {
            hideSize: true,
            hideTheme: false,
            fontMinSize: 6,
            fontMaxSize: 176,
            startWithSize: 16,
            startWithColor: 'color-5',
        },
    },
    _b);
export var getLayoutSettingsProps = function (layoutType, section) {
    var settingsByLayoutType = section === SECTION_MY_POSTS
        ? LAYOUT_SETTINGS_PROPS_MY_POSTS
        : LAYOUT_SETTINGS_PROPS;
    return settingsByLayoutType[layoutType];
};
export var getLayoutDefaultFontSize = function (layoutType, section, contentType) {
    var layoutSettingsProps = getLayoutSettingsProps(layoutType, section);
    return get(layoutSettingsProps, "".concat(contentType, ".startWithSize"));
};
export var LAYOUT_DEFAULT_FONT_FAMILY = 'open sans';
export var LAYOUT_DEFAULT_CSS_FONT_FAMILY = '"open sans", sans-serif';
export var LAYOUT_DEFAULT_FONT = {
    displayName: 'Paragraph 2',
    editorKey: 'font_2',
    family: LAYOUT_DEFAULT_FONT_FAMILY,
    fontStyleParam: true,
    preset: 'Custom',
    size: 15,
    style: {
        bold: false,
        italic: false,
        underline: false,
    },
    value: "font:normal normal normal 15px/21px ".concat(LAYOUT_DEFAULT_CSS_FONT_FAMILY),
};
// Post advanced design options
export var POST_HEADER_TWO_FONT = 'post-headerTwoFont';
export var POST_HEADER_TWO_COLOR = 'post-headerTwoColor';
export var POST_HEADER_THREE_FONT = 'post-headerThreeFont';
export var POST_HEADER_THREE_COLOR = 'post-headerThreeColor';
export var POST_HEADER_FOUR_FONT = 'post-headerFourFont';
export var POST_HEADER_FOUR_COLOR = 'post-headerFourColor';
export var POST_HEADER_FIVE_FONT = 'post-headerFiveFont';
export var POST_HEADER_FIVE_COLOR = 'post-headerFiveColor';
export var POST_HEADER_SIX_FONT = 'post-headerSixFont';
export var POST_HEADER_SIX_COLOR = 'post-headerSixColor';
export var POST_QUOTES_FONT = 'post-quotesFont';
export var POST_QUOTES_COLOR = 'post-quotesColor';
export var POST_HEADER_TWO_FONT_SIZE_MOBILE = 'post-mobile-headerTwoFontSize';
export var POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_HEADER_TWO_FONT_SIZE_MOBILE);
export var POST_HEADER_THREE_FONT_SIZE_MOBILE = 'post-mobile-headerThreeFontSize';
export var POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_HEADER_THREE_FONT_SIZE_MOBILE);
export var POST_HEADER_FOUR_FONT_SIZE_MOBILE = 'post-mobile-headerFourFontSize';
export var POST_HEADER_FOUR_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_HEADER_FOUR_FONT_SIZE_MOBILE);
export var POST_HEADER_FIVE_FONT_SIZE_MOBILE = 'post-mobile-headerFiveFontSize';
export var POST_HEADER_FIVE_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_HEADER_FIVE_FONT_SIZE_MOBILE);
export var POST_HEADER_SIX_FONT_SIZE_MOBILE = 'post-mobile-headerSixFontSize';
export var POST_HEADER_SIX_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_HEADER_SIX_FONT_SIZE_MOBILE);
export var POST_QUOTES_FONT_SIZE_MOBILE = 'post-mobile-quotesFontSize';
export var POST_QUOTES_FONT_SIZE_MOBILE_PATH = "style.numbers.".concat(POST_QUOTES_FONT_SIZE_MOBILE);
export var IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED = 'post-isAdvancedDesignOptionsEnabled';
export var IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED_PATH = "style.booleans.".concat(IS_POST_ADVANCED_DESIGN_OPTIONS_ENABLED);
// Button design params
export var BUTTON_COLOR = 'button-color';
export var BUTTON_COLOR_PATH = "style.colors.".concat(BUTTON_COLOR);
export var BUTTON_TEXT_COLOR = 'button-textColor';
export var SECONDARY_ICON_COLOR = 'icon-secondaryColor';
export var SECONDARY_ICON_COLOR_PATH = "style.colors.".concat(SECONDARY_ICON_COLOR);
// Link and hashtag params
export var LINK_AND_HASHTAG_COLOR = 'blog-linkHashtagColor';
export var POST_HOMEPAGE_LINK_AND_HASHTAG_COLOR = 'post-homepage-linkHashtagColor';
// Widget params
export var WIDGET_SIGN_UP_BUTTON_TYPE = 'widget-signUpButtonType';
export var WIDGET_SIGN_UP_BUTTON_TYPE_PATH = "style.numbers.".concat(WIDGET_SIGN_UP_BUTTON_TYPE);
export var WIDGET_SIGN_UP_BUTTON_BORDER_COLOR = 'widget-signUpButtonBorderColor';
export var WIDGET_SIGN_UP_BUTTON_BORDER_COLOR_PATH = "style.colors.".concat(WIDGET_SIGN_UP_BUTTON_BORDER_COLOR);
export var WIDGET_SIGN_UP_BUTTON_BACKGROUND_COLOR = 'widget-signUpButtonBackgroundColor';
export var WIDGET_SIGN_UP_BUTTON_BACKGROUND_COLOR_PATH = "style.colors.".concat(WIDGET_SIGN_UP_BUTTON_BACKGROUND_COLOR);
export var WIDGET_SIGN_UP_BUTTON_FONT = 'widget-signUpButtonFont';
export var WIDGET_SIGN_UP_BUTTON_FONT_PATH = "style.fonts.".concat(WIDGET_SIGN_UP_BUTTON_FONT);
export var WIDGET_SIGN_UP_BUTTON_PRIMARY_COLOR = 'widget-signUpButtonPrimaryColor';
export var WIDGET_SIGN_UP_BUTTON_PRIMARY_COLOR_PATH = "style.fonts.".concat(WIDGET_SIGN_UP_BUTTON_PRIMARY_COLOR);
export var WIDGET_SIGN_UP_BUTTON_SECONDARY_COLOR = 'widget-signUpButtonSecondaryColor';
export var WIDGET_SIGN_UP_BUTTON_SECONDARY_COLOR_PATH = "style.fonts.".concat(WIDGET_SIGN_UP_BUTTON_SECONDARY_COLOR);
export var WIDGET_NOTIFICATION_ICON_COLOR = 'widget-notificationIconColor';
export var WIDGET_NOTIFICATION_ICON_COLOR_PATH = "style.colors.".concat(WIDGET_NOTIFICATION_ICON_COLOR);
export var WIDGET_NOTIFICATION_BUBBLE_COLOR = 'widget-notificationBubbleColor';
export var WIDGET_NOTIFICATION_BUBBLE_COLOR_PATH = "style.colors.".concat(WIDGET_NOTIFICATION_BUBBLE_COLOR);
export var WIDGET_NOTIFICATION_BUBBLE_TEXT_COLOR = 'widget-notificationBubbleTextColor';
export var WIDGET_NOTIFICATION_BUBBLE_TEXT_COLOR_PATH = "style.colors.".concat(WIDGET_NOTIFICATION_BUBBLE_TEXT_COLOR);
export var WIDGET_AVATAR_BORDER_COLOR = 'widget-avatarBorderColor';
export var WIDGET_AVATAR_BORDER_COLOR_PATH = "style.colors.".concat(WIDGET_AVATAR_BORDER_COLOR);
// Blog menu
export var IS_BLOG_MENU_ENABLED = 'blogMenu-isEnabled';
export var IS_BLOG_MENU_ENABLED_PATH = "style.booleans.".concat(IS_BLOG_MENU_ENABLED);
export var IS_BLOG_MENU_CATEGORY_LABELS_ENABLED = 'blogMenu-isCategoryLabelsEnabled';
export var IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH = "style.booleans.".concat(IS_BLOG_MENU_CATEGORY_LABELS_ENABLED);
export var IS_BLOG_MENU_SEARCH_ENABLED = 'blogMenu-isSearchEnabled';
export var IS_BLOG_MENU_SEARCH_ENABLED_PATH = "style.booleans.".concat(IS_BLOG_MENU_SEARCH_ENABLED);
export var IS_BLOG_MENU_LOGIN_BUTTON_ENABLED = 'widget-isLoginButtonEnabled';
export var IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH = "style.booleans.".concat(IS_BLOG_MENU_LOGIN_BUTTON_ENABLED);
// Social sharing
export var IS_SOCIAL_SHARING_ENABLED = 'socialSharing-isEnabled';
export var IS_SOCIAL_SHARING_ENABLED_PATH = "style.booleans.".concat(IS_SOCIAL_SHARING_ENABLED);
export var getIsSocialSharingProviderEnabledParam = function (provider, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return "socialSharing-".concat(isMobile ? 'mobile-' : '').concat(provider);
};
export var getIsSocialSharingProviderEnabledParamPath = function (provider, isMobile) {
    if (isMobile === void 0) { isMobile = false; }
    return "style.booleans.".concat(getIsSocialSharingProviderEnabledParam(provider, isMobile));
};
// Category header params
export var IS_CATEGORY_HEADER_ENABLED = 'categoryHeader-isEnabled';
export var IS_CATEGORY_HEADER_ENABLED_PATH = "style.booleans.".concat(IS_CATEGORY_HEADER_ENABLED);
// Banner params
export var IS_BANNER_ENABLED = 'banner-isEnabled';
export var IS_BANNER_ENABLED_PATH = "style.booleans.".concat(IS_BANNER_ENABLED);
// Pagination
var getPaginationParamPath = function (section, param) {
    return "pagination-".concat(section, "-").concat(param);
};
export var getPaginationTypeParam = function (section) {
    if (section === void 0) { section = SECTION_HOMEPAGE; }
    return getPaginationParamPath(section, 'type');
};
export var getPaginationTypeParamPath = function (section) {
    if (section === void 0) { section = SECTION_HOMEPAGE; }
    return "style.numbers.".concat(getPaginationTypeParam(section));
};
export var getPaginationPageSizeParam = function (section) {
    if (section === void 0) { section = SECTION_HOMEPAGE; }
    return getPaginationParamPath(section, 'pageSize');
};
export var getPaginationPageSizeParamPath = function (section) {
    if (section === void 0) { section = SECTION_HOMEPAGE; }
    return "style.numbers.".concat(getPaginationPageSizeParam(section));
};
